import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { getParams } from '../../common/AuraFunctions';

export const getProducts = createAsyncThunk('eCommerceApp/products/getProducts',
  async ({ params, goToFirstPage }, { dispatch }) => {
    const { filterObj, headerObj } = getParams(goToFirstPage)
    const headers = { 'App-Pagination-Limit': 10, 'App-Pagination-Num': 0, ...headerObj };
    const response = await axios.get('/api/products', { params: { ...params, ...filterObj }, headers });
    const data = await response.data.data;
    if (data.length) {
      dispatch(getProductPolishes({ type: params.productType }))
    }
    dispatch(setProductListHeaders(response.headers))
    return data;
  });


export const getProductColors = createAsyncThunk(
  'eCommerceApp/products/getProductColors',
  async ({ params }) => {
    const response = await axios.get('/api/stoneColors', { params });
    const data = await response.data;
    return { data, params };
  }
);
export const getProductTypes = createAsyncThunk(
  'eCommerceApp/products/getProductTypes',
  async ({ params }) => {
    const response = await axios.get('/api/productTypes', { params });
    const data = await response.data;
    return { data, params };
  }
);
export const getProductSubTypes = createAsyncThunk(
  'eCommerceApp/products/getProductSubTypes',
  async ({ path = '', params = {} }) => {
    const urlPath =  path || '/api/productSubTypes';
    const response = await axios.get(urlPath, { params });
    const data = await response.data;
    return { data, params };
  }
);
export const getProductPolishes = createAsyncThunk(
  'eCommerceApp/products/getProductPolishes',
  async ({ path = '', params = {}}) => {
    const urlPath = path || '/api/polishes';
    const response = await axios.get(urlPath, { params });
    const data = await response?.data;
    return { data };
  }
);

export const uploadProducts = createAsyncThunk(
  'eCommerceApp/products/uploadProducts',
  async (params, { dispatch, getState }) => {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    formData.append('file', params.file);
    const response = await axios.post('/api/importProducts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(getProductColors({ params: { on: 'y' } }));
    dispatch(getProductTypes({ params: { on: 'y' } }));
    dispatch(getProductSubTypes({ params: { on: 'y' } }));
    dispatch(getProductPolishes({}));

    const uploadResponse = await response.data;
    return uploadResponse;
  }
);

export const removeProducts = createAsyncThunk(
  'eCommerceApp/products/removeProducts',
  async (productIds, { dispatch, getState }) => {
    await axios.post('/api/e-commerce-app/remove-products', { productIds });
    return productIds;
  }
);

const productsAdapter = createEntityAdapter({});

export const { selectAll: selectProducts, selectById: selectProductById } =
  productsAdapter.getSelectors((state) => state.eCommerceApp.products);

const productsSlice = createSlice({
  name: 'eCommerceApp/products',
  initialState: productsAdapter.getInitialState({
    searchText: '',
    productColorNames: [],
    productColors: [],
    productTypeNames: [],
    selectedProductType: '',
    productTypes: [],
    productSubTypeNames: [],
    productSubTypes: [],
    selectedProductSubType: '',
    productPolishes: [],
    selectedProductPolish: '',
    productListHeaders: {},
    selectedProducts: [],
    unmodifiedProducts: []
  }),
  reducers: {
    setProductsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event ? event.target.value : '' }),
    },
    setSelection: {
      reducer: (state, action) => {
        state[action.payload.field] = action.payload.value;
      },
      prepare: (field, event) => ({ payload: { field, value: event ? event.target.value : '' } }),
    },
    setProductListHeaders: (state, action) => {
      state.productListHeaders = action.payload
    },
    setSelectedProducts: (state, action) => {
      if (action.payload.type === 'dialog')
        state.unmodifiedProducts = action.payload.data
      else
        state.selectedProducts = action.payload.data
    },
    updateProductSubTypeAndPolishes:(state, action)=>{
      state.productSubTypes = action.payload.data;
      state.productPolishes = action.payload.data;
    },
    updateProductPolishes:(state, action)=>{
      state.productPolishes = action.payload.data;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: productsAdapter.setAll,
    [getProducts.pending]: productsAdapter.removeAll,
    [getProductColors.fulfilled]: (state, action) => {
      if (action.payload.params && action.payload.params.on === 'y') {
        state.productColorNames = action.payload.data;
      } else {
        state.productColors = action.payload.data;
      }
    },
    [getProductTypes.fulfilled]: (state, action) => {
      if (action.payload.params && action.payload.params.on === 'y') {
        state.productTypeNames = action.payload.data;
      } else {
        state.productTypes = action.payload.data;
      }
    },
    [getProductSubTypes.fulfilled]: (state, action) => {
      if (action.payload.params && action.payload.params.on === 'y') {
        state.productSubTypeNames = action.payload.data;
      } else {
        state.productSubTypes = action.payload.data || [];
      }
    },
    [getProductPolishes.fulfilled]: (state, action) => {
      state.productPolishes = action.payload?.data || [];
    },
    [removeProducts.fulfilled]: (state, action) =>
      productsAdapter.removeMany(state, action.payload),
  },
});

export const { setProductsSearchText, setSelection, setProductColors, setProductListHeaders, setSelectedProducts, updateProductSubTypeAndPolishes, updateProductPolishes } = productsSlice.actions;

export default productsSlice.reducer;
