import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { addOrderItem } from 'app/main/apps/e-commerce/store/orderSlice';
import { productInitValues } from '../product/ProductBase';

export const getProduct = createAsyncThunk('eCommerceApp/product/getProduct', async (id) => {
  const response = await axios.get(`/api/products/${id}`);
  const data = await response.data.product;
  return productInitValues(data);
});

export const getProductDetails = createAsyncThunk(
  'eCommerceApp/product/getProductDetails',
  async ({ id, params }, { dispatch, getState }) => {
    const response = await axios.get(`/api/productInfo/${id}`, { params });
    const data = await response.data.product;
    return productInitValues(data);
  }
);

export const getSalesHistoryDetails = createAsyncThunk(
  'eCommerceApp/product/getSalesHistoryDetails',
  async ({ id = '', params }, { dispatch, getState }) => {
    if(id !== 'new'){
      const response = await axios.get(`/api/salesHistory/${id}`);
      const data = await response.data;
     return data
    }
    return {}
  }
);

export const saveProduct = createAsyncThunk(
  'eCommerceApp/product/saveProduct',
  async (productData, { dispatch, getState }) => {
    const { product } = getState().eCommerceApp;
    let response;
    if (productData.id && !productData?.cloneProduct) {
      response = await axios.put(`/api/products/${productData.id}`, {
        ...product,
        ...productData,
      });
    } else {
      response = await axios.post('/api/products', {
        ...product,
        ...productData,
      });
    }
    const data = await productData.id && !productData?.cloneProduct ? response.data.product : response.data.data;
    if (productData.isCustomProduct === 'yes') {
      data.order_quantity = productData.order_quantity;
      data.discount = productData.discount;
      data.unit_cost = productData.unit_cost;
      data.weight = productData.weight;
      data.is_custom = productData.is_custom;
      data.customer_provider_number = productData.customer_provider_number
      data.comments = productData.comments
      data.decedent = productData.decedent
    }
    const value = productInitValues(data);
    if (productData.addToOrderList) {
      dispatch(addOrderItem(value));
    }
    return value;
  }
);

export const AddUpdateProductToCart = createAsyncThunk(
  'eCommerceApp/product/AddUpdateProductToCart',
  async ({ customerId, productId, val }, { dispatch, getState }) => {
    const response = await axios.post('/api/cartItems', {
      customerId,
      productId,
      val,
    });
    const data = await response.data;
    return data;
  }
);

export const initState = {
  loading: false,
  data: null,
  salesHistory: [],
  productDialog: {
    props: {
      open: false,
    },
    data: null,
  },
};

const productSlice = createSlice({
  name: 'eCommerceApp/product',
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    newProduct: {
      reducer: (state, action) => {
        state.data = action.payload;
      },
      prepare: (event) => ({
        payload: productInitValues(),
      }),
    },
    resetProduct: (state, action) => {
      state.data = null;
    },
    openProductDialog: (state, action) => {
      state.productDialog = {
        props: {
          open: true,
        },
        data: action.payload.data,
        param: action.payload.param,
      };
    },
    closeProductDialog: (state, action) => {
      state.productDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
  },
  extraReducers: {
    [getProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getProductDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getSalesHistoryDetails.fulfilled]: (state, action) => {
      state.salesHistory = action.payload;
    },
    [saveProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [saveProduct.rejected]: (state, action) => {
      state.loading = false;
    },
    [AddUpdateProductToCart.fulfilled]: (state, action) => {
      // TODO verify this
      state.loading = false;
    },
  },
});

export const {
  newProduct,
  resetProduct,
  setLoading,
  resetLoading,
  openProductDialog,
  closeProductDialog,
} = productSlice.actions;

export default productSlice.reducer;
