import { Box, Button, Toolbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { makeStyles } from '@mui/styles';
import { openDialog } from 'app/store/fuse/dialogSlice';
import clsx from "clsx";
import { useEffect } from "react";
import Confirm from "@fuse/core/FuseDialog/confirm";
import { showMessage } from "app/store/fuse/messageSlice";
import { setSelectedCustomers, createMultipleLogin } from "../store/customersSlice";

const CustomerToolBar = (props) => {
    const dispatch = useDispatch();
    const { toolbarParam } = props;
    const { selectedCustomers, filterType, loadPage } = toolbarParam
    const numSelected = selectedCustomers?.length;
    const useToolbarStyles = makeStyles((theme) => ({
        root: {
            minHeight: '45px',
            paddingLeft: '0px',
            color: theme.palette.text.primary,
        },
        colorBox: {},
    }));
    const classes = useToolbarStyles();
    useEffect(() => {
        dispatch(setSelectedCustomers({ data: [] }))
    }, [filterType])
    return selectedCustomers && selectedCustomers.length > 0 ? (
        <Toolbar className={clsx(classes.root, 'flex items-start')}>
            <div className="flex flex-row items-center">
                <Box className="flex items-center font-medium">{numSelected}</Box>
                <Typography className="pl-8 font-medium" color="inherit">
                    {toolbarParam.selectionMsg ? toolbarParam.selectionMsg : 'Row(s) Selected'}
                </Typography>
            </div>
            <div className="flex flex-row items-center">
                <Button
                    color="secondary"
                    aria-label="Archive Product"
                    size="small"
                    variant="contained"
                    sx={{ padding: '0px 15px', marginLeft: "10px" }}
                    disabled={!selectedCustomers.length}
                    onClick={() =>
                        dispatch(
                            openDialog({
                                children: (
                                    <Confirm title="Create login confirmation"
                                        content="Are you sure you want to create login for selected customers?"
                                        onSubmit={() => {
                                            dispatch(createMultipleLogin(selectedCustomers)).then(res => {
                                                if (!res.error) {
                                                    dispatch(showMessage({ message: `Created login for selected items`, type: 'success' }))
                                                    loadPage()
                                                    dispatch(setSelectedCustomers({ data: [] }))
                                                }
                                            })
                                        }
                                        }
                                    />
                                )
                            }))
                    }
                >
                    Create Login
                </Button>
            </div>
        </Toolbar>
    ) : (
        <></>
    );
};
export default CustomerToolBar;
