import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';

export const getWidgets = createAsyncThunk('DashboardApp/widgets/getWidgets', async () => {
  const response = await axios.get('/api/widgetData');
  const data = await response.data.widgets;
  return data;
});

export const getChartData = createAsyncThunk('DashboardApp/widgets/getTopSales', async (params) => {
  const response = await axios.get('/api/graphApi', { params })
  const data = await response.data.orders
  return data;
})

export const getEcomWidgetsOrder = createAsyncThunk(
  'DashboardApp/widgets/getEcomWidgetsOrder',
  async (inputData) => {
    if (inputData.stage === 'completed') inputData.stage = 'submitted'
    const response = await axios.post('/api/widgetOrderData', inputData);
    const data = await response.data.orders;
    return data;
  }
);




export const getEcomWidgetsOrderItems = createAsyncThunk(
  'DashboardApp/widgets/getEcomWidgetsOrderItems',
  async (params) => {
    const response = await axios.post(`/api/widgetOrderItemData`, null, { params });
    const getResponse = () => {
      switch (params.stage) {
        case 'invoice':
          if (params?.invoiceId) {
            return response.data
          }
          return response.data.invoice;
        case 'shipment':
        case 'in-production':
          return response.data.shipping_label.orders;
        default:
          return response.data.orderItems
      }
    }
    const data = await getResponse();
    return data;
  }
);

export const getShipmentInfo = createAsyncThunk(
  'DashboardApp/widgets/getShipmentInfo',
  async (params) => {
    const response = await axios.get(`/api/getShipmentInfo`, { params });
    const data = response.data.orders
    return data;
  }
);


export const viewNote = createAsyncThunk('/api/viewNote', async ({ id }) => {
  const response = await axios.get(`/api/viewNote/${id}`)
  return response.data.note_history
})

export const getOrderNotes = createAsyncThunk('/api/getOrderNotes', async ({ id }) => {
  const response = await axios.get(`/api/getOrderNotes/${id}`)
  return response.data.notes
})

export const getContainerNumbers = createAsyncThunk('/api/getContainerNumbers', async () => {
  const response = await axios.get('/api/getContainerNumbers');
  return response.data;
})

export const getOrdersOfContainerNumber = createAsyncThunk('/api/getContainerNumbers', async (params) => {
  const response = axios.get('/api/receiveOrders', { params });
  return response;
})

export const recieveOrder = createAsyncThunk('/api/recieveOrder', async (params) => {
  const response = axios.put('/api/receiveOrders', params)
  return response
})

export const getEcomWidgetsInvoice = createAsyncThunk(
  'DashboardApp/widgets/getEcomWidgetsInvoice',
  async (inputData) => {
    const response = await axios.post('/api/widgetInvoiceData', inputData);
    const data = await response.data.invoice;
    return data;
  }
);

export const getEcomWidgetsRfq = createAsyncThunk(
  'DashboardApp/widgets/getEcomWidgetsRfq',
  async (orderId) => {
    const response = await axios.post('/api/widgetRfqData', orderId);
    const data = await response.data;
    return data;
  }
);

export const makeItReview = createAsyncThunk(
  'DashboardApp/widgets/makeItReview',
  async (payload, { dispatch, getState }) => {
    const state = getState()
    const response = await axios.put(`/api/orders/${payload.id}`,
      { ...payload, status: state.mainDashboardApp.widgets.statuses.find((s) => s.name === 'In-Review') });
    const data = await response.data.order;
    dispatch(
      showMessage({
        message: 'Status updated successfully',
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    );
    return data;
  }
);

export const getStatuses = createAsyncThunk(
  'DashboardApp/widgets/getStatuses',
  async (params) => {
    const response = await axios.get('/api/categoryValues', { params: { c: params } });
    const data = await response.data;
    return data?.filter(r => r.name !== 'Inventory Received')
  }
);

export const confirmInvoice = createAsyncThunk('DashboardApp/widgets/confirmInvoice',
  async ({ id, param }) => {
    const response = await axios.put(`/api/invoice/${id}`, param);
    return response;
  }
)

export const confirmSupplierInvoice = createAsyncThunk('DashboardApp/widgets/confirmInvoice',
  async ({ id }) => {
    const response = await axios.put(`/api/shipment/${id}`, {
      confirm_invoice: true
    });
    return response;
  }
)

const widgetsAdapter = createEntityAdapter({});

export const { selectEntities: selectWidgets } = widgetsAdapter.getSelectors(
  (state) => state.mainDashboardApp.widgets
);

const initialValue = {
  props: {
    open: false,
  },
  data: null,
  param: null,
  dashboard: true,
  statuses: [],
  shipmentInfo: []

};

const defaultField = 'viewOrderDialog';

export const initState = {
  loading: false,
  viewOrderDialog: initialValue,
  orderDialog: initialValue,
  confirmInvoiceDialog: initialValue,
  default: 'viewOrderDialog',
  recieveOrderDialog: { props: false },
  ordersOfContainerNumber: null
};

const widgetsSlice = createSlice({
  name: 'DashboardApp/widgets',
  initialState: initState,
  reducers: {
    openRecieveOrderDialog: (state, action) => {
      state.recieveOrderDialog = {
        props: {
          open: true,
        },

      }
    },
    closeRecieveOrderDialog: (state, action) => {
      state.recieveOrderDialog = {
        props: {
          open: false
        }
      }
    },
    openViewOrderDialog: (state, action) => {
      state[action?.payload?.field || defaultField] = {
        props: {
          open: true,
        },
        stage: action.payload.stage,
        data: action.payload.data,
        param: action.payload.param,
        completeOrderData: action.payload.completeOrderData,
        dashboard: true,
      };
    },
    closeViewOrderDialog: (state, action) => {
      state[action?.payload?.field || defaultField] = {
        props: {
          open: false,
        },
        data: null,
        param: null,
        dashboard: true,
      };
    },
    setOrdersOfContainerNumber: (state, action) => {
      state.ordersOfContainerNumber = action.payload
    },
  },
  extraReducers: {
    [getWidgets.fulfilled]: widgetsAdapter.setAll,
    [getStatuses.fulfilled]: (state, action) => {
      state.statuses = action.payload
    },
    [getStatuses.rejected]: (state, action) => {
      state.statuses = []
    },
    [getShipmentInfo.fulfilled]: (state, action) => {
      state.shipmentInfo = action.payload
    }
  },
});

export const { openViewOrderDialog, closeViewOrderDialog, openRecieveOrderDialog, closeRecieveOrderDialog, setOrdersOfContainerNumber } = widgetsSlice.actions;

export default widgetsSlice.reducer;
