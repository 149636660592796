import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { AddShoppingCart, MoreVert, RequestQuote } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const currentUser = useSelector(({ user }) => user);
  const { role } = currentUser
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState();
  const openActionMenu = Boolean(anchorEl);
  const location = useLocation();

  const setAnchorElement = (e) => {
    setAnchorEl(e.currentTarget);
  }

  function handleItemClick(item) {
    if (isMobile) {
      dispatch(navbarCloseMobile());
    }
  }

  const More = () => {
    let moreAction = [];
    switch (role) {
      case 'customer':
        moreAction = [
          {
            title: 'Place Order',
            to: '/products',
            icon: 'material-solid:add_shopping_cart'
          },
          {
            title: 'Request For Quote',
            to: '/rfqs/request/new?type=request',
            icon: 'material-solid:request_quote'
          }
        ];
        break;
      case 'supplier':
        moreAction = [
          {
            title: ' Create Container ',
            to: '/shipments/new',
            icon: 'material-solid:local_shipping'
          },
        ];
        break;
      default:
        break;
    }

    return moreAction;
  };

  const filterNavigation = () =>{
    const navigationItems =  navigation.filter(item=> item?.auth?.includes(role));
    console.log("navigationItems", navigationItems);
    return navigationItems || []
  }

  return useMemo(() => {
    return (
      <div className={clsx('flex', { 'flex-col': isMobile })}>
        <div>
          <FuseNavigation
            className={clsx('navigation', props.className)}
            navigation={filterNavigation()}
            // navigation={navigation}
            layout={props.layout}
            dense={props.dense}
            active={props.active}
            onItemClick={handleItemClick}
          />
        </div>
        {["customer", "supplier"].includes(role) && location.pathname !== '/dashboard' && (<div className={clsx({ 'm-16': isMobile })}>
          <Tooltip title="more">
            <IconButton onClick={(e) => setAnchorElement(e)}>
              <MoreVert />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={openActionMenu}
            onClose={() => { setAnchorEl(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'bottom',
            }}
          >

            {More().map(val =>
              <MenuItem
                component={Link}
                to={val.to}
                onClick={() => { setAnchorEl(null) }}>
                <div className="flex gap-10">
                  <FuseSvgIcon>{val.icon}</FuseSvgIcon>
                  <span>{val.title}</span>
                </div>

              </MenuItem>)}
          </Menu>

        </div>)}
      </div>
    );
  }, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout, anchorEl, location.pathname]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
