import { cloneDeep } from "lodash"

export const generateInitialBoxNumber = (orders = [], orderItem = '') => {
  let previusOrderItem = {}
  const copyOrders = cloneDeep(orders)
  if (copyOrders?.length) {
    copyOrders.forEach((order, orderIndex) => {
      if (order?.is_selected === 1 && order?.order_detail?.length) {
        order?.order_detail.forEach((detail, detailIndex) => {

          if (detail?.is_selected === 1) {
            detail.generateBox = true;
            if (Object.keys(previusOrderItem)?.length) {
              detail.box_number_from = parseInt(previusOrderItem?.box_number_to, 10) + 1;
              detail.box_number_to = parseInt(detail.box_number_from - 1, 10) + parseInt(detail?.quantity, 10)
            } else {
              detail.box_number_from = 1;
              detail.box_number_to = parseInt(detail?.quantity, 10);
            }

            previusOrderItem = detail;
          }
        })
      }
    })
  }
  return copyOrders
}

export const resetBoxNumbersForUncheckedOrderItems = (orders = []) => {
  let previusOrderItem = {}
  const copyOrders = cloneDeep(orders)
  if (copyOrders?.length) {
    copyOrders.forEach((order, orderIndex) => {
      if (order?.order_detail?.length) {
        if (order?.is_selected === 1) {
          order?.order_detail.forEach((detail, detailIndex) => {
            detail.generateBox = false;
            if (!detail?.is_selected) {
              detail.box_number_from = '';
              detail.box_number_to = '';
            } else if (detail.box_number_from && detail.box_number_to) {
              detail.generateBox = true;
              if (Object.keys(previusOrderItem)?.length) {
                detail.box_number_from = parseInt(previusOrderItem?.box_number_to, 10) + 1;
                detail.box_number_to = parseInt(detail.box_number_from - 1, 10) + parseInt(detail?.quantity, 10)
              } else {
                detail.box_number_from = 1;
                detail.box_number_to = parseInt(detail?.quantity, 10)
              }
              previusOrderItem = detail;
            }
          })
        } else {
          order.order_detail.forEach((detail, detailIndex) => {
            detail.box_number_from = '';
            detail.box_number_to = ''
            detail.generateBox = false;
          })
        }
      }
    })
  }
  return copyOrders
}

export const updateBoxFromAndToNumbers = (shipment, customerId, shipmentIndex, orderIndex, detailIndex, field) => {
  const copyShipment = cloneDeep(shipment);
  let previusOrderItem = {}
  if (copyShipment?.length) {
    copyShipment.forEach((ship, eleShipIndex) => {
      if (ship?.orders?.length) {
        ship.orders.forEach((order, eleOrderIndex) => {
          if (order?.is_selected === 1 && order?.order_detail?.length && orderIndex <= eleOrderIndex) {
            order.order_detail.forEach((detail, eleDetailIndex) => {
              if (detail?.is_selected === 1 && detail?.generateBox) {
                if (orderIndex === eleOrderIndex) {
                  if (field === 'box_number_from') {
                    if (detailIndex === eleDetailIndex) {
                      detail.box_number_to = parseInt(detail.box_number_from, 10) + parseInt(detail?.newQuantity || detail.quantity, 10)
                    } else if (detailIndex < eleDetailIndex && Object.keys(previusOrderItem)?.length) {
                      detail.box_number_from = parseInt(previusOrderItem?.box_number_to, 10) + 1
                      detail.box_number_to = parseInt(detail.box_number_from - 1, 10) + parseInt(detail?.newQuantity || detail.quantity, 10)
                    }
                  } else if (field === 'box_number_to' && Object.keys(previusOrderItem)?.length) {
                    if (detailIndex < eleDetailIndex) {
                      detail.box_number_from = parseInt(previusOrderItem?.box_number_to, 10) + 1
                      detail.box_number_to = parseInt(detail.box_number_from - 1, 10) + parseInt(detail?.newQuantity || detail.quantity, 10)
                    }
                  }
                  previusOrderItem = detail;
                } else {
                  detail.box_number_from = parseInt(previusOrderItem?.box_number_to, 10) + 1
                  detail.box_number_to = parseInt(detail.box_number_from - 1, 10) + parseInt(detail?.newQuantity || detail.quantity, 10);
                  previusOrderItem = detail;
                }
              }
            })
          }
        })
      }
    })
  }
  return copyShipment
}
