import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsers = createAsyncThunk(
  'employeesApp/employees/getUsers',
  async (routeParams, { getState }) => {
    routeParams = routeParams || getState().employeesApp.employees.routeParams;
    const response = await axios.get('/api/employees', {
      params: routeParams,
    });
    const data = await response.data.data;

    return { data, routeParams };
  }
);

export const getRoles = createAsyncThunk(
  'employeesApp/employees/getRoles',
  async (param, { getState }) => {
    const response = await axios.get('/api/roles', {
      params: {
        e: 1,
        ec: 1,
      },
    });
    const roles = await response.data.data;
    return { roles };
  }
);

export const removeUsers = createAsyncThunk(
  'employeesApp/employees/removeUsers',
  async (userIds, { dispatch, getState }) => {
    await axios.post('/api/client-users-app/remove-client-users', { userIds });

    return userIds;
  }
);

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUsersById } = usersAdapter.getSelectors(
  (state) => state.employeesApp.employees
);

const employeesSlice = createSlice({
  name: 'employeesApp/employees',
  initialState: usersAdapter.getInitialState({
    searchText: '',
    routeParams: {},
  }),
  reducers: {
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      usersAdapter.setAll(state, data);
      state.routeParams = routeParams;
      state.searchText = '';
    },
    [removeUsers.fulfilled]: (state, action) => usersAdapter.removeMany(state, action.payload),
    [getRoles.fulfilled]: (state, action) => {},
  },
});

export const {
  setUsersSearchText,
  openNewUserDialog,
  closeNewUserDialog,
  openEditUserDialog,
  closeEditUserDialog,
} = employeesSlice.actions;

export default employeesSlice.reducer;
