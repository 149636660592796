import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { capitalize, lowerCase, upperCase } from 'lodash';
import { isCustomer, isSupplier } from 'app/main/apps/common/AuraFunctions';
import MenuActions from 'app/main/dashboards/main/dialogs/MenuActions';
import { selectActions, selectUser } from 'app/store/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useFormContext } from 'react-hook-form';
import { getOrders, openReallocationDialog } from '../store/ordersSlice';
import {
  openOrderDialog,
  openViewOrderDialog,
  openChooseSupplierDialog,
  convertToOrder,
  updateOrder,
  getQuotedSuppliers,
  openSupplierShippingDialog,
  closeViewOrderDialog,
  performAction,
  getShippingLabelData,
  getOrderDetails,
  closeOrderDialog,
  openConfirmInvoiceDialog,
} from '../store/orderSlice';
import { getEcomWidgetsOrderItems, getShipmentInfo, viewNote, getOrderNotes } from '../../../dashboards/main/store/widgetsSlice';
import { getSuppliers } from '../store/rfqsSlice';
import { openProductDialog } from '../store/productSlice';
import downloadFile from '../../common/AuraDownload';



function OrdersRowActions({ row, cell, closeDialog, popupButtons, approveProof, approveNote, listData, selectedImages }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const allaActions = useSelector(selectActions);
  const user = useSelector(selectUser);
  const locationSearchKey = window.location.search;
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(locationSearchKey);
  const sidValue = urlParams.get('sid');

  const buildSelectedDrawing = (selectedImagesData, fullDetails) => {
    if (fullDetails?.length === 0) return []
    return fullDetails.map((detail, index) => {
      const selectedDocs = selectedImagesData[index] || [];
      const selectedDocIds = selectedDocs.map(doc => doc.id);

      const filteredDocuments = detail?.drawing_documents?.filter(doc => selectedDocIds.includes(doc.id));

      return {
        ...detail,
        vas: detail?.vas ? JSON.stringify(detail.vas) : null,
        drawing_documents: filteredDocuments
      };
    });
  };

  const methods = useFormContext()
  const { setError } = methods || {}

  const onCellClick = (ev) => {
    ev.stopPropagation();
  };
  const { original } = row;

  const type = location.pathname.split('/')[1];
  const type2 = location.pathname.split('/')[2]

  const getStage = () => {
    let stage = '';
    if (type === 'supplier-invoices') {
      stage = 'invoice';
    } else if (['in-production', 'fulfilled', 'rfd', 'completed', 'confirmed'].includes(type)) {
      stage = type;
    } else {
      stage = type.slice(0, -1);
    }
    return stage;
  };

  let stage = getStage()
  if (type?.includes('awaiting')) stage = 'order'
  const stageAction = allaActions.filter((action) => action.stage === stage);
  let actions = stageAction.find((action) =>
    (action.fromStatusesTypeCode === original.status.system_code) && action.fromStatusesTypeCode)?.actionList[0];
  if (actions) actions = JSON.parse(actions);
  if (type === 'supplier-invoices') actions = actions?.filter(el => !(['cancel', 'visibility', 'alarm'].includes(el.icon)))


  if (row?.original?.is_stone_order && !row?.original?.is_proof_approval && actions) {
    actions = actions.filter(el => el.name !== 'Create Supplier Order')
  }
  if (!row?.original?.is_stone_order && row?.original?.is_proof_approval && actions) {
    actions = actions.filter(el => el.name !== 'Create Stone Order')

  }
  if (!row.original.is_send_to_supplier_for_proof && actions) {
    actions = actions.filter(el => el.name !== 'Send to Supplier for Proof')
  }

  if (sidValue === "all" && actions) {
    actions = actions.filter(el => ['View Order', 'Receive Order'].includes(el.name))
  }


  let count = -1;
  const showMore = actions?.length > 3;
  const key = stage === 'invoice' ? 'invoice_id' : 'oid';
  let getTitle;

  if (['in-production', 'fulfilled', 'confirmed'].includes(stage)) {
    getTitle = 'order';
  } else if (stage === 'completed') {
    getTitle = 'rfd';
  } else {
    getTitle = stage;
  }
  const allowActions = [
    'EDIT_ORDER',
    'EDIT_QUOTE',
    'VIEW_ORDER',
    'VIEW_QUOTE',
    'CONVERT_TO_QUOTE',
    'CONVERT_TO_PROOF',
    'EDIT_RFQ',
    'VIEW_RFQ',
    'EDIT',
    'SHIPMENT_PRINT',
    'EDIT_SHIPMENT',
    'PRINT_PRODUCTION',
    'STATUS_UPDATE',
    'REVISE_QUOTE',
    'REVIEW_NOTE'
  ];

  const basedOnImageCustomzation = () => {
    let Key = ''
    if (original.image_customisation) {
      Key = 'update_status_with_attachment'
    }
    else {
      Key = 'updateStatus'
    }
    return Key
  }

  const refreshTableData = (id) => {
    let params = {}
    if (type2 === 'containers')
      params = { stage: 'container' }
    else if (type?.includes('awaiting'))
      params = { title: 'proof' }
    else
      params = { stage: id === 'STATUS_UPDATE' && stage === 'quote' ? 'order' : stage }

    dispatch(getOrders({ params }))
  };

  const confirmation = (toStatusCode, id, otherPayload = {}, widgetData, code, approvalPayload) => {
    let action;
    const orderDetails = [];
    const approveQuoteSuccessMessageForCustomer = id === 'STATUS_UPDATE' && isCustomer(user.role) ? 'Quote has been Approved Successfully' : ''
    const performApi = ['MAKE_IN_PROGRESS', 'CANCEL_INVOICE', 'SEND_REMINDER', 'RFQ_REMINDER', 'UPLOAD_DOCUMENT'].includes(id)
    const isApproval = ['REJECT_DRAWING', 'REQUEST_REVISION_FOR_DRAWING', 'APPROVE_DRAWING',
      'SEND_FOR_DRAWING_APPROVAL', 'SEND_FOR_PROOF_APPROVAL', 'REQUEST_REVISION_FOR_PROOF', 'APPROVE_PROOF', 'REJECT', 'APPROVE_VAS_PRICE', 'SEND_PRICE_APPROVAL_FOR_VAS', 'MAKE_SHIPMENT', 'REQUEST_REVISION_FOR_VAS_PRICE'].includes(id)
    const isAttachmentPayload = ['SEND_FOR_DRAWING_APPROVAL', 'SEND_FOR_PROOF_APPROVAL'].includes(id)
    if (isAttachmentPayload) {
      otherPayload?.order_details?.forEach((item, index) => {
        orderDetails.push({
          id: widgetData[index]?.id,
          vas: id === 'SEND_FOR_PROOF_APPROVAL' ? JSON.stringify(widgetData[index]?.vas) : item.vas,
          ...item
        })
      })
      action = dispatch(
        updateOrder({
          id: original.id,
          to_statuses_type_id: code || toStatusCode,
          [isApproval ? 'update_status_with_attachment' : basedOnImageCustomzation()]: 'yes',
          note: otherPayload?.note,
          order_details: orderDetails
        })
      )
    }

    else if (performApi)
      action = dispatch(performAction({
        ui_id: id,
        data: {
          [id === 'UPLOAD_DOCUMENT' ? 'shipment_id' : 'id']: id === 'UPLOAD_DOCUMENT' ? original.shipment_id : original.id,
          ...(id === 'UPLOAD_DOCUMENT' && { orders: [...approvalPayload] })
        }
      }));

    else
      action = dispatch(
        updateOrder({
          id: original.id,
          to_statuses_type_id: code || toStatusCode,
          [isApproval ? 'update_status_with_attachment' : basedOnImageCustomzation()]: 'yes',
          ...otherPayload
        })
      )
    action.then((res) => {
      if (!res.error) {
        dispatch(showMessage({ type: 'success', message: approveQuoteSuccessMessageForCustomer || 'Success' }));
        refreshTableData(id)
        dispatch(closeViewOrderDialog());
        if (id === 'STATUS_UPDATE' && stage === 'quote') navigate("/orders")
      } else {
        throw new Error(res.data.message ? res.data.message : 'Failed to update');
      }
    }).catch((error) => {
      dispatch(showMessage({ type: 'error', message: error.message }));
    });
  };

  const checkData = (data) => {
    let validProduct = true;
    data.every((product) => {
      if (!product.color || !product.type || !product.length || !product.width || !product.height) {
        validProduct = false;
      }
      return null;
    })

    return validProduct;

  }

  const defaultAction = [
    {
      dashboardAction: true,
      icon: "preview",
      name: `View ${capitalize(getTitle)}`,
      to_statuses_types_code: "",
      to_statuses_types_id: "",
      ui_id: `VIEW_${upperCase(getTitle)}`,
    },
  ]

  const paramForSupplier = ['art/design', 'stone']
  const workFlowAction = ['Send to Supplier for Proof', 'Send to Supplier']

  const getApprovalResetData = (data, showDocuments = false) => {
    const resetData = { order_details: [] }
    data?.forEach((item) => {
      resetData.order_details.push(
        {
          id: item.id,
          vas: item.vas,
          showDocuments
        }
      )
    })
    return resetData
  }

  const exportShipment = (isPrice) => {
    return (
      downloadFile({ params: { id: row.original.shipment_id, is_price: isPrice }, endPoint: '/api/exportShipment' })
        .then(() => {
          dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' }))
          dispatch(closeOrderDialog({ field: 'orderDialog', }))
        })
        .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))

    )
  }

  const additionalButton = () => {
    return (
      <div className='flex gap-10'>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => { exportShipment(true) }}
        >
          Export Shipment With Price
        </Button>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => { exportShipment(false) }}
        >
          Export Shipment Without Price
        </Button>
      </div>
    )
  }

  const handleClick = (id, toStatusCode, name, toStatusId) => {
    console.log(id, "id");
    switch (id) {
      case 'ADD_NOTE':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: original,
            param: {
              name: 'note',
              title: 'Add Note',
              successMessage: 'Your note has been sent successfully',
            },
            stage
          })
        );
        break;
      case 'APPROVE_SHIPMENT':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: original,
            param: {
              name: 'approveActions',
              title: name,
              successMessage: 'Success',
              submitButtonName: 'Submit',
              loadOrdersFun: refreshTableData,
            },
            stage
          })
        );
        break;
      case 'REJECT_SHIPMENT':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: original,
            param: {
              name: 'approveActions',
              title: name,
              successMessage: 'Success',
              submitButtonName: 'Submit',
              loadOrdersFun: refreshTableData,
            },
            stage
          })
        );
        break;
      case 'QUICK_EDIT':
        dispatch(getOrderDetails(({ id: original.id, params: {} }))).then(({ payload }) => {
          dispatch(openOrderDialog({
            completeOrderData: original,
            data: payload,
            param: {
              name: 'edit_order',
              title: `Edit ${stage.replace(/^\w/, (c) => c.toUpperCase())} (Basic Info)`,
              successMessage: 'Success',
              submitButtonName: 'Save',
              loadOrdersFun: refreshTableData,
            },
            field: 'orderDialog',
            stage
          }))
        })
        break;

      case 'CONFIRM_INVOICE':
        if (stage === 'shipment' && original.status?.system_code === 'WAITING_TO_BE_INVOICED') {
          dispatch(
            openConfirmInvoiceDialog({
              data: { id: original?.shipment_id },
              param: {
                loadOrdersFun: refreshTableData,
              },
              stage
            })
          )
        } else {
          dispatch(
            openOrderDialog({
              completeOrderData: original,
              data: original,
              param: {
                name: 'confirm_invoice',
                title: 'Confirm Invoice',
                successMessage: 'Invoice has been confirmed and sent to the customer',
                loadOrdersFun: refreshTableData,
              },
              stage
            })
          )
        }
        break;

      case 'VIEW_NOTE':
        dispatch(viewNote({ id: original.id })).then((data) => {
          dispatch(
            openOrderDialog({
              completeOrderData: original,
              data: data.payload,
              param: {
                name: 'note',
                title: name,
              },
            })
          );
        })
        break;
      case 'VIEW_IN PRODUCTION':
      case 'VIEW_ORDER':
      case 'VIEW_QUOTE':
      case 'VIEW_RFQ':
      case 'VIEW_RFD':
      case 'VIEW_PAYMENT':
        if (!cell.column.userManage || ['VIEW_PAYMENT'].includes(id))
          dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
            (data) => {
              if (data && data.payload) {
                // setOrderItems(data.payload);
                dispatch(
                  openViewOrderDialog({
                    completeOrderData: original,
                    data: data.payload,
                    param: {
                      name: id === 'VIEW_PAYMENT' ? 'payment' : 'viewOrder',
                      type: 'View',
                      title: id === 'VIEW_PAYMENT' ? 'Payment History' : `View ${capitalize(getTitle)} Items`,
                      // showDialogActions: !cell.column.userManage ? true : null,
                      is_vas_order: original?.is_vas_order
                    },
                    stage
                  })
                );
              }
            }
          );
        break;

      case 'VIEW_SHIPMENT':
        dispatch(getEcomWidgetsOrderItems({ shipment_id: original.shipment_id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name,
                    type: 'View',
                    title: name,
                    showDialogActions: true,
                    loadOrdersFun: refreshTableData
                  },
                  stage,
                })
              );
            }
          }
        );
        break;


      case 'REVIEW_ORDER':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name: 'Review',
                    type: 'View',
                    title: `Review ${capitalize(getTitle)} `,
                    showDialogActions: !cell.column.userManage ? true : null,
                    loadOrdersFun: refreshTableData
                  },
                  stage,
                })
              );
            }
          }
        );
        break;

      case 'CONFIRM_ORDER':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: {
              id: original.id,
              action: 'status',
              status: 'Confirmed',
              order_no: row.original.order_no
            },
            param: {
              name: 'confirm',
              submitButtonName: 'Confirm Order',
              title: 'Confirm Order',
              successMessage: 'Order status has been updated successfully',
              loadOrdersFun: refreshTableData
            },
            stage
          })
        );
        break;

      case 'CONVERT_TO_ORDER':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name,
                    type: 'approve',
                    submitButtonName: name,
                    title: name,
                    onSubmit: () => {
                      dispatch(convertToOrder({ id: original.id, to_status_id: toStatusId }))
                        .unwrap()
                        .then(refreshTableData)
                    }
                  },
                  stage
                })
              );
            }
          }
        );
        break;

      case 'RE_ALLOCATION':
        dispatch(
          openReallocationDialog({
            param: {
              name: 'reallocation',
              title: 'Re Allocation',
              submitButtonName: 'Submit',
              selectedFrom: original.id,
            },
            stage
          })
        );
        break;
      case 'SEND_TO_SUPPLIER':
        dispatch(getEcomWidgetsOrderItems({ oid: original.id, stage })).then(data => {
          if (checkData(data.payload)) {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    id: original.id,
                    name: 'send to supplier',
                    type: 'View',
                    title: name,
                    showDialogActions: false,
                    hideDialogActions: false,
                    submitButtonName: name,
                    action: name
                  },
                  stage,
                })
              );
            }
            // dispatch(
            //   openViewOrderDialog({
            //     completeOrderData: original,
            //     param: {
            //       id: original.id,
            //       name: 'send to supplier',
            //       type: 'View',
            //       title: name,
            //       maxWidth: 'md',
            //       submitButtonName: name,
            //       action: name
            //     },
            //     stage
            //   }))
          } else {
            dispatch(showMessage({
              message: 'Products related fields are missing Please fill in before sending to supplier',
              variant: 'error',
              autoHideDuration: null,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }))
          }
        })

        if (workFlowAction.includes(name)) {
          const index = workFlowAction.indexOf(name)
          const params = { type: paramForSupplier[index] }
          dispatch(getSuppliers({ suppliers: original.suppliers || [], params }));
        }
        break;

      case 'CHOOSE_SUPPLIER':
        dispatch(
          openChooseSupplierDialog({
            param: original.id,
            completeOrderData: original,
            name,
            status: original.status.name,
            stage
          })
        );
        break;






      case 'VIEW_SHIPPING_INFO':
      case 'CREATE_SHIPPING':
        dispatch(
          openSupplierShippingDialog({
            param: original.id,
            completeOrderData: original,
            type: id === 'VIEW_SHIPPING_INFO' ? 'view' : 'create',
          })
        );
        break;

      case 'REJECT':
      case 'REQUEST_REVISION_FOR_PROOF':
      case 'APPROVE_PROOF':
      case 'REJECT_DRAWING':
      case 'REQUEST_REVISION_FOR_DRAWING':
      case 'APPROVE_DRAWING':
      case 'RECEIVE_ORDER':
      case 'CANCEL_INVOICE':
      case 'SEND_REMINDER':
      case 'RFQ_REMINDER':
      case 'APPROVE_VAS_PRICE':
      case 'REQUEST_REVISION_FOR_VAS_PRICE':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name,
                    type: 'approve',
                    submitButtonName: name === 'Receive Order' ? 'Order Receive' : name,
                    title: name,
                    is_proof_approval: original.is_proof_approval,
                    onSubmit: (note) => confirmation(toStatusCode, id, { note }, data.payload),
                    actionName: id
                  },
                  stage
                })
              );
            }
          }
        );
        break;

      case 'STATUS_UPDATE':
        if (stage !== 'rfq' && cell.column.userManage)
          dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
            (data) => {
              if (data && data.payload) {
                dispatch(
                  openViewOrderDialog({
                    completeOrderData: original,
                    data: data.payload,
                    param: {
                      name,
                      type: 'approve',
                      submitButtonName: name,
                      title: name,
                      is_proof_approval: original.is_proof_approval,
                      onSubmit: (note) => confirmation(toStatusCode, id, { note }, data.payload),
                    },
                    stage
                  })
                );
              }
            }
          );
        break;
      case 'REVIEW_QUOTE':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name: 'Review Quote',
                    type: 'View',
                    title: name,
                    showDialogActions: true,
                    hideDialogActions: true,
                  },
                  stage,
                })
              );
            }
          }
        );
        break;
      case 'MAKE_IN_PROGRESS':
      case 'SEND_FOR_ORDER_APPROVAL':
      case 'SEND_FOR_PROOF_APPROVAL':
      case 'SEND_FOR_DRAWING_APPROVAL':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            console.log('orderData.is_proof_approval', original.is_proof_approval)
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name,
                    type: 'approve',
                    submitButtonName: name,
                    title: name,
                    is_proof_approval: original.is_proof_approval,
                    showAttachment: true,
                    showPriceField: !['SEND_FOR_PROOF_APPROVAL', 'MAKE_IN_PROGRESS'].includes(id),
                    sendFormValue: true,
                    onSubmit: (formData, otherParam) => confirmation(toStatusCode, id, formData, data.payload, otherParam),
                    resetFormData: getApprovalResetData(data.payload, id === 'MAKE_IN_PROGRESS'),
                    resetForm: true,
                  },
                  stage
                })
              );
            }
          }
        );
        break;


      case 'UPLOAD_DOCUMENT':
        dispatch(getShipmentInfo({ id: original.shipment_id })).then((data) => {
          if (data && data.payload) {
            dispatch(
              openViewOrderDialog({
                completeOrderData: original,
                data: data.payload,
                param: {
                  name: 'Send for Approval',
                  type: 'approve',
                  title: name,
                  submitButtonName: name,
                  toStatusCode,
                  onSubmit: (formData, otherParam, approvalPayload) => confirmation(toStatusCode, id, formData, data.payload, otherParam, approvalPayload),
                  showPriceField: true,
                  isApproval: original.is_approval_type,
                  documentApproval: true
                },
                stage,
              }))
          }
        });
        break;
      case 'SEND_PRICE_APPROVAL_FOR_VAS':
      case 'MAKE_SHIPMENT':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name,
                    type: 'approve',
                    submitButtonName: name === "Review VAS Price" ? "Send Price Approval for VAS" : name,
                    showApproveButton: true,
                    title: name,
                    resetFormData: getApprovalResetData(data.payload, id === 'SEND_PRICE_APPROVAL_FOR_VAS'),
                    resetForm: true,
                    sendFormValue: id === 'SEND_PRICE_APPROVAL_FOR_VAS',
                    showPriceField: true,
                    onSubmit: (formData, code) => confirmation(toStatusCode, id, formData, data.payload, code)
                  },
                  stage
                })
              );
            }
          }
        );
        break;


      case 'REJECT_QUOTE':
        dispatch(getEcomWidgetsOrderItems({ [key]: original.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openOrderDialog({
                  completeOrderData: original,
                  data: data.payload,
                  param: {
                    name: 'rejectQuote',
                    title: name,
                    successMessage: 'Your note has been sent successfully',
                    submitButtonName: name,
                    onSubmit: ({ note }) => {
                      confirmation(toStatusCode, id, { note })
                    }
                  },
                })
              );
            }
          }
        );
        break;

      case 'MAKE_PAYMENT':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: original,
            param: {
              name: 'payment',
              title: 'Add Payment',
              successMessage: 'Success',
              loadOrdersFun: refreshTableData
            },
            stage,
          })
        );
        break;

      case 'VIEW_INVOICE':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: original,
            param: {
              name: 'invoice',
              title: 'Invoice Details',
            },
            stage
          })
        );
        break;

      case 'STATUS_CHANGE':
        dispatch(
          openOrderDialog({
            completeOrderData: original,
            data: original,
            param: {
              name: 'status',
              title: 'Update Status',
              loadOrdersFun: refreshTableData
            },
            stage
          })
        );
        break;

      case 'CREATE_VAS_ORDER':
      case 'CREATE_SUPPLIER_ORDER':
      case 'CREATE_DIRECT_SHIPMENT_SUPPLIER_ORDER':
        if ((['CREATE_SUPPLIER_ORDER', 'CREATE_VAS_ORDER'].includes(id) && original.show_inventory_dialog) || id === 'CREATE_DIRECT_SHIPMENT_SUPPLIER_ORDER') {
          dispatch(
            openProductDialog({
              param: {
                name: 'supplierOrderDirectShipment',
                title: name,
                orderNo: original.id,
                stage,
                loadProducts: refreshTableData,
                action: name
              },
            })
          );
        }
        if (['CREATE_VAS_ORDER', 'CREATE_SUPPLIER_ORDER'].includes(id) && !original.show_inventory_dialog) {
          dispatch(
            openViewOrderDialog({
              completeOrderData: original,
              param: {
                id: original.id,
                name: 'create supplier order',
                type: 'View',
                title: name,
                maxWidth: 'md',
                submitButtonName: 'Create Supplier Order',
                action: name
              },
              stage
            })
          );
          dispatch(getQuotedSuppliers({ id: original.id }));
        }
        break;

      case 'EXPORT_SHIPMENT':
        downloadFile({ params: { id: row.original.shipment_id }, endPoint: '/api/exportShipment' })
          .then(() => dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' })))
          .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))
        break;


      case 'EXPORT_SHIPMENT_WITH_PRICE':
        dispatch(openOrderDialog({
          completeOrderData: original,
          data: { id: original.shipment_id },
          param: {
            name: '',
            title: name,
            successMessage: 'Invoice has been generated',
            submitButtonName: name,
            loadOrdersFun: refreshTableData,
            hideDialogActions: true,
            additionalButton: additionalButton()
          },
          field: 'orderDialog',
          stage
        }))
        break;

      case 'EXPORT_INVOICE_CUSTOMER':
        downloadFile({ params: { id: row.original.id, }, endPoint: '/api/exportInvoice' })
          .then(() => dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' })))
          .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))
        break;

      case 'COMPLETE_AND_SHIP':
        dispatch(openOrderDialog({
          completeOrderData: original,
          data: { id: original.shipment_id },
          param: {
            name: 'complete_shipment',
            title: name,
            successMessage: 'Completed And Shipped',
            submitButtonName: name,
            loadOrdersFun: refreshTableData,
            maxWidth: 'md'
          },
          stage
        }))
        break;
      // is_drawing_approval
      // : 
      // true
      // is_proof_approval
      // : 
      // false
      case 'GENERATE_INVOICE':
        dispatch(openOrderDialog({
          completeOrderData: original,
          data: { id: original.shipment_id },
          param: {
            name: 'generate_invoice',
            title: name,
            successMessage: 'Invoice has been generated',
            submitButtonName: name,
            loadOrdersFun: refreshTableData,
          },
          field: 'orderDialog',
          stage
        }))
        break;

      default:
        break;
    }
  };

  const pathFunction = (uiId) => {
    switch (uiId) {
      case 'CONVERT_TO_QUOTE':
        // TODO Need to clarify whether this is correct
        if (user.role === 'supplier') return `/rfqs/supplier-quotes/${original.id}`;
        if (['admin', 'staff', 'sales'].includes(user.role)) return `/rfqs/admin-quotes/${original.id}?type=convert-to-quote`
        return `/quotes/${original.id}`
      case 'CONVERT_TO_PROOF':
        return `/rfd/supplier-quotes/${original.id}`
      case 'EDIT':
      case 'EDIT_RFQ':
      case 'EDIT_ORDER':
      case 'EDIT_QUOTE':
      case 'VIEW_ORDER':
      case 'VIEW_QUOTE':
        return `/${type}/${original.id}`
      case 'REVIEW_NOTE': {
        let title = ''
        let redirectionType = type
        if ((isCustomer(user.role) || isSupplier(user.role)) && !['rfq', 'quotes'].includes(type)) {
          if (stage === 'rfd') {
            title = 'Rfp'
            redirectionType = 'rfds'
          }
          if (stage === 'completed') {
            title = 'Rfp Completed'
            redirectionType = 'completed'
          }
          if (stage === 'order') {
            title = isCustomer(user.role) ? 'Orders' : 'Received Order'
            redirectionType = 'orders'
          }
          if (stage === 'confirmed') {
            title = 'Confirmed Order'
            redirectionType = 'confirmed'
          }
          if (stage === 'rfq') {
            redirectionType = 'rfqs'
          }
        }
        if (type === 'rfqs') {
          if (isCustomer(user.role) || isSupplier(user.role)) {
            redirectionType = 'rfqs'
          } else {
            redirectionType = 'rfq'
          }
        }
        if (stage === 'order' && original?.status?.title === 'proof' && isCustomer(user.role)) {
          redirectionType = 'awaiting-approval'
        }
        return `/${redirectionType}/${original.id}?type=view&tab=6&title=${title}`
      }
      case 'VIEW_RFQ':
        if (cell.column.userManage) {
          return `/rfq/${original.id}`
        }
        return ''
      case 'REVISE_QUOTE':
        if (cell.column.userManage) {
          return `/${type}/${original.id}?type=revise-quote`
        }
        return ''
      // return cell.column.userManage || isCustomer(user.role) ? `/${type}/${original.id}` : '';
      case 'SHIPMENT_PRINT':
        return `/shipments/shippingLabel/${original.shipment_id}`;
      case 'PRINT_PRODUCTION':
        return `/shipments/printProduction/${original.shipment_id}`
      case 'EDIT_SHIPMENT':
        return `/shipments/${original.shipment_id}`
      case 'STATUS_UPDATE':
        if (stage === 'rfq' && cell.column.userManage) {
          return `/${type}/${original.id}?type=in-review`
        }
        return ''
      default:
        return '';
    }
  };

  const getRoute = (path, uiId) => {
    if (cell.column.userManage && lowerCase(uiId).includes('view') && uiId !== 'REVIEW_NOTE')
      return `${path}?type=view`
    return path
  }

  const menuObj = {
    handleClick,
    pathFunction,
    allowActions,
    getRoute,
    closeDialog,
    closeViewOrderDialog,
  }

  const checkShow = (uiId) => {
    if (cell.column.userManage && uiId !== 'VIEW_SHIPMENT') {
      return true;
    }
    if (['VIEW_ORDER', 'VIEW_QUOTE', 'VIEW_RFQ', 'REVIEW_ORDER', 'VIEW_SHIPMENT'].includes(uiId)) {
      return false;
    }
    return true;
  }


  const showActions = (id) => {
    switch (id) {
      case 'GENERATE_INVOICE':
        return original.is_invoiced
      case 'SEND_FOR_PROOF_APPROVAL':
        return original.is_proof_approval
      case 'SEND_FOR_DRAWING_APPROVAL':
        return original.is_drawing_approval
      case 'APPROVE_PROOF':
      case 'APPROVE_VAS_PRICE':
      case 'APPROVE_DRAWING':
      case 'REQUEST_REVISION_FOR_VAS_PRICE':
      case 'REQUEST_REVISION_FOR_PROOF':
      case 'REQUEST_REVISION_FOR_DRAWING':
      case 'REJECT':
      case 'REJECT_DRAWING':
        return isCustomer(user.role) ? approveProof : true
      case 'UPLOAD_DOCUMENT':
        return original.is_approval_type
      case 'EXPORT_INVOICE_CUSTOMER':
        if (user.role === "customer") return true
        return false
      case 'REJECT_QUOTE':
      case 'STATUS_UPDATE':
        if (isCustomer(user.role)) {
          return approveProof
        }
        if (cell.column.userManage && stage === "quote" && !popupButtons) {
          return false
        }
        return true
      default:
        return true;
    }
  }
  let rearrangedActions;



  if (actions) {
    if (stage === 'order') {
      rearrangedActions = actions;
    } else if (stage === 'quote') {
      if (isCustomer(user.role)) {
        rearrangedActions = actions
      }
      else if (popupButtons && cell.column.userManage) {
        rearrangedActions = [actions[1], actions[2], actions[3]]
      }
      else rearrangedActions = [...actions.slice(1), actions[0]];
    } else {
      rearrangedActions = [...actions.slice(2), ...actions.slice(0, 2)];
    }
  }

  const showDefaultAction = () => {
    switch (original.status.name) {
      case 'Confirmed':
        if (isCustomer(user.role)) {
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  const getDetails = () => {
    let stageValue = { id: '', key: '', widgetId: '' };
    switch (stage) {
      case 'invoice':
        stageValue = {
          id: original.invoice_no,
          key: 'invoice_id',
          widgetId: original.id
        }
        break;
      case 'container':
      case 'in-production':
        stageValue = {
          id: original.container_number,
          key: 'shipment_id',
          widgetId: original.shipment_id
        }
        break;
      default:
        stageValue = {
          id: original.order_no,
          key: 'oid',
          widgetId: original.id
        }
    }

    return stageValue;
  };

  const getStageTitle = (isTooltipTitle) => {
    const stageData = stage
    switch (stageData) {
      case 'fulfilled':
      case 'confirmed':
      case 'order':
        return `View Order Details`;
      case 'quote':
        return `View Quote Details`;
      case 'rfq':
        return `View Rfq Details`;
      case 'invoice':
        return `View Invoice`;
      case 'approval':
        return `View Approval Items`;
      case 'shipment':
        return 'View Shipment';
      case 'container':
        return 'View Container Shipment'
      case 'in-production':
        return 'View In-production'
      default:
        return "";
    }
  }

  const handleViewOrder = () => {
    dispatch(getEcomWidgetsOrderItems({ [getDetails().key]: getDetails().widgetId, stage: stage === 'container' ? 'in-production' : stage, invoiceId: true })).then((data) => {
      if (data && data.payload) {
        // setOrderItems(data.payload);
        dispatch(
          openViewOrderDialog({
            completeOrderData: original,
            stage: stage === 'container' ? 'in-production' : stage,
            data: stage === 'invoice' ? data.payload.invoice : data.payload,
            param: {
              name: 'viewOrder',
              type: 'View',
              title: getStageTitle(false),
              showDialogActions: true,
              is_vas_order: original?.is_vas_order,
              packingSlips: data.payload?.packing_slips || [],
              supplierInvoices: data.payload?.supplier_invoices || [],
            },
          })
        );
      }
    })
  }

  const handleHideAction = (name) => {
    if (name === 'Revisions To Supplier' && original?.suppliers?.length === 0) return true
    return false
  }

  return (
    <div
      onClick={onCellClick}
      onKeyDown={onCellClick}
      role="button"
      tabIndex="0"
      style={{ cursor: 'default', pointerEvents: 'auto' }}
      className="flex flex-row"
    >
      {!actions && stage !== "fulfilled" && showDefaultAction() && !isCustomer(user.role) && !isSupplier(user.role) && <>
        {defaultAction.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStatusCode, to_statuses_types_id: toStatusId }) => {
          if (showActions(uiId)) {
            if (popupButtons) {
              if (checkShow(uiId)) {
                count += 1;
                return (
                  <div>
                    {count < 3 &&
                      <Button
                        className='mr-5'
                        color="secondary"
                        variant="contained"
                        startIcon={<IconButton
                          className="py-0 px-2"
                          size="small"
                        >
                          <Icon fontSize="small" sx={{ color: 'white' }}>{icon}</Icon>
                        </IconButton>}
                        component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                        to={getRoute(pathFunction(uiId), uiId)}
                        onClick={() => {
                          dispatch(closeViewOrderDialog());
                          handleClick(uiId, toStatusCode, name, toStatusId);
                        }}
                        sx={{ width: 'auto' }}
                      >
                        <span style={{ fontSize: 12 }}>{name}</span>
                      </Button>
                    }
                  </div>
                )
              }
              return null;
            }
            return (
              <Tooltip key={uiId} title={name}>
                <IconButton
                  className="py-0 px-2"
                  size="small"
                  component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                  to={getRoute(pathFunction(uiId), uiId)}
                  onClick={() => handleClick(uiId, toStatusCode, name, toStatusId)}
                >
                  <Icon fontSize="small">{icon}</Icon>
                </IconButton>
              </Tooltip>

            );
          }
          return null;
        })}
      </>}
      {!locationSearchKey.includes('&archive=true') &&
        <div className="flex items-center">
          {actions && approveProof === undefined &&
            actions.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStatusCode, to_statuses_types_id: toStatusId }) => {
              if (handleHideAction(name)) return null
              if (showActions(uiId)) {
                if (popupButtons) {
                  if (checkShow(uiId)) {
                    count += 1;
                    return (
                      <div>
                        {count < 3 &&
                          <></>
                          // <Button
                          //   className='mr-5'
                          //   color="secondary"
                          //   variant="contained"
                          //   startIcon={<IconButton
                          //     className="py-0 px-2"
                          //     size="small"
                          //   >
                          //     <Icon fontSize="small" sx={{ color: 'white' }}>{icon}</Icon>
                          //   </IconButton>}
                          //   component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                          //   to={getRoute(pathFunction(uiId), uiId)}
                          //   onClick={() => {
                          //     dispatch(closeViewOrderDialog());
                          //     handleClick(uiId, toStatusCode, name, toStatusId);
                          //   }}
                          //   sx={{ width: 'auto' }}
                          // >
                          //   <span style={{ fontSize: 12 }}>{name}</span>
                          // </Button>
                        }
                      </div>
                    )
                  }
                  return null;
                }
                return (
                  <Tooltip key={uiId} title={name}>
                    <IconButton
                      className="py-0 px-2"
                      size="small"
                      component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                      to={getRoute(pathFunction(uiId), uiId)}
                      onClick={() => handleClick(uiId, toStatusCode, name, toStatusId)}
                    >
                      <Icon fontSize="small">{icon}</Icon>
                    </IconButton>
                  </Tooltip>

                );
              }
              return null;
            })}

          {
            actions && approveProof === undefined && original?.is_show_drawing_icon && (
              <Tooltip title="View Attached Diagram">
                <IconButton
                  className="py-0 px-2"
                  size="small"
                  onClick={() => handleViewOrder()}
                >
                  <Icon fontSize="small">attach_file</Icon>
                </IconButton>
              </Tooltip>
            )
          }


          {actions && approveProof &&
            rearrangedActions.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStatusCode, to_statuses_types_id: toStatusId }) => {
              if (showActions(uiId)) {
                if (popupButtons) {
                  if (checkShow(uiId)) {
                    count += 1;
                    return (
                      <div>
                        {count < 3 &&
                          <Button
                            className='mr-5'
                            color="secondary"
                            variant="contained"
                            startIcon={<IconButton
                              className="py-0 px-2"
                              size="small"
                            >
                              <Icon fontSize="small" sx={{ color: 'white' }}>{icon}</Icon>
                            </IconButton>}
                            component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                            to={getRoute(pathFunction(uiId), uiId)}
                            onClick={() => {
                              if (['Approve Quote', 'Send for Quote Approval'].includes(name)) {
                                const orderDetails = buildSelectedDrawing(selectedImages, listData)
                                confirmation(toStatusCode, uiId, { updateStatus: 'yes', order_details: orderDetails })
                              }
                              else {
                                dispatch(
                                  openOrderDialog({
                                    completeOrderData: original,
                                    data: original,
                                    param: {
                                      name: 'approveActions',
                                      title: name,
                                      successMessage: 'Success',
                                      submitButtonName: 'Submit',
                                      loadOrdersFun: refreshTableData,
                                      data: {
                                        uiId,
                                        toStatusCode
                                      }
                                    },
                                    stage
                                  })
                                );
                              }
                            }}
                            sx={{ width: 'auto' }}
                          >
                            <span style={{ fontSize: 12 }}>{name}</span>
                          </Button>
                        }
                      </div>
                    )
                  }
                  return null;
                }

              }
              return null;
            })}

          {showMore && approveProof === undefined && count >= 3 ? <MenuActions actions={actions.slice(3)} obj={menuObj} /> : ''}


          {/* Temporarily hidden this action */}
          {/* {cell.column.isManage && (
          <>
            {original.payment_status !== 'paid' && (
              <IconButton
                className="p-0"
                onClick={(ev) => {
                  ev.stopPropagation();
                  dispatch(
                    openOrderDialog({
                      completeOrderData: values,
                      data: original,
                      param: { name: 'payment', title: 'Add Payment', loadOrders: 'yes' },
                    })
                  );
                }}
                size="medium"
              >
                <Icon fontSize="small">attach_money</Icon>
              </IconButton>
            )}
          </>
        )} */}


          {/* Temporarily hidden this action
          {showMore && approveProof && count >= 3 ? <MenuActions actions={rearrangedActions.slice(4)} obj={menuObj} /> : ''} */}

        </div>
      }
    </div >
  );
}

export default OrdersRowActions;
