import * as yup from 'yup';
import { formatDateString, emptySchema } from 'app/main/apps/common/AuraFunctions';

export function getCustomerInitValues(data) {
  return {
    is_primary: data && data.is_primary ? data.is_primary : null,
    id: data && data.id ? data.id : null,
    user_id: data && data.user_id ? data.user_id : null,
    company_name: data && data.company_name ? data.company_name : '',
    first_name: data && data.first_name ? data.first_name : '',
    last_name: data && data.last_name ? data.last_name : '',
    email: data && data.email ? data.email : '',
    phone: data && data.phone ? data.phone : '',
    mobile: data && data.mobile ? data.mobile : '',
    // incorporation_date:
    //   data && data.incorporation_date
    //     ? formatDateString(data.incorporation_date, 'yyyy-MM-dd')
    //     : '',
    website: data && data.website ? data.website : '',
    // registration_num: data && data.registration_num ? data.registration_num : '',
    create_login: data && data.create_login ? data.create_login : false,
    is_include_collaborator: data && data.is_include_collaborator ? data.is_include_collaborator : false,
    invoice_cc: data && data.invoice_cc ? data.invoice_cc : '',
    address_id: data && data.address_id ? data.address_id : null,
    sales_person: data?.sales_person,
    address: {
      address_line1:
        data && data.address && data.address.address_line1 ? data.address.address_line1 : '',
      address_line2:
        data && data.address && data.address.address_line2 ? data.address.address_line2 : '',
      city: data && data.address && data.address.city ? data.address.city : '',
      postcode: data && data.address && data.address.postcode ? data.address.postcode : '',
      region: data && data.address && data.address.region ? data.address.region : '',
    },
    customer_shipment_profile: data && (!data?.customer_shipment_profile || data?.customer_shipment_profile?.length === 0) ? [{ address: data.address, is_default: true }] : data?.customer_shipment_profile,
    logins: data && data.logins ? data.logins : [],
    devices: data && data.devices ? data.devices : [],
    profile_id: data && data.profile_id ? data.profile_id : null,
    profile: {
      id: data && data.profile && data.profile.id ? data.profile.id : null,
      domestic_discount:
        data && data.profile && data.profile.domestic_discount
          ? parseFloat(data.profile.domestic_discount)
          : '',
      india_import_discount:
        data && data.profile && data.profile.india_import_discount
          ? parseFloat(data.profile.india_import_discount)
          : '',
      china_import_discount:
        data && data.profile && data.profile.china_import_discount
          ? parseFloat(data.profile.china_import_discount)
          : '',
      custom_discount:
        data && data.profile && data.profile.custom_discount
          ? parseFloat(data.profile.custom_discount)
          : '',
      custom_margin:
        data && data.profile && data.profile.custom_margin
          ? parseFloat(data.profile.custom_margin)
          : '',
    },

  };
}

/**
 * Form Init Values
 */
export default function getInitValues(type, data) {
  if (type && type === 'customer') {
    return getCustomerInitValues(data);
  }
  return { id: data && data.id ? data.id : null };
}

export const customerSchema = (title) => {
  return yup.object().shape({
    company_name: yup.string().required('You must enter a business name'),
    first_name: yup.string().required('You must enter a first name'),
    last_name: yup.string().required('You must enter a last name'),
    email: yup.string().required('You must enter a email').email('You must enter a valid e-mail.'),
    ...(title === 'Add Customer' ? {
      address: yup.object().shape({
        address_line1: yup.string().required('You must enter a address line 1'),
        city: yup.string().required('You must enter a city'),
        region: yup.string().required('You must enter a region'),
        postcode: yup.string().required('You must enter a postcode'),
      })
    } : {})
  })
}
//   invoice_cc: yup.string()
//   .transform((value, originalValue) => {
//     const emails = originalValue.split(',').map((email) => email.trim());
// //    return Array.isArray(emails) ? emails.join(', ') : emails;
//     return emails;  
//   }).email('You must enter a valid e-mail.'),
// });
/**
 * Form Validation Schema
 */
export function getSchema(type, title) {
  if (type && type === 'customer') {
    return customerSchema(title);
  }
  return emptySchema;
}
