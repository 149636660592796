import clsx from 'clsx';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import OrdersToolBar from 'app/main/apps/e-commerce/orders/OrdersToolBar';
import ProductToolBar from '../e-commerce/products/ProductToolBar';
import CustomerToolBar from '../client/customers/CustomerToolBar';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    minHeight: '45px',
    paddingLeft: '0px',
    color: theme.palette.text.primary,
  },
  colorBox: {},
}));

const AuraTableToolBar = (props) => {
  const classes = useToolbarStyles();
  const { selectedRowIds, toolbarParam } = props;
  const numSelected = Object.keys(selectedRowIds).length;
  return numSelected && numSelected > 0 ? (
    <Toolbar className={clsx(classes.root, 'flex items-start')}>
      <div className="flex flex-row items-center">
        <Box className="flex items-center font-medium font-bold">{numSelected}</Box>
        <Typography className="pl-8 font-medium" color="inherit">
          {toolbarParam.selectionMsg ? toolbarParam.selectionMsg : 'Row(s) Selected'}
        </Typography>
      </div>
    </Toolbar>
  ) : (
    <></>
  );
};

function getComponent(props) {
  switch (props.toolbarParam.pageName) {
    case 'orders':
      return <OrdersToolBar {...props} />;
    case 'products':
      return <ProductToolBar {...props} />
    case 'customers':
      return <CustomerToolBar {...props} />
    default:
      return <AuraTableToolBar {...props} />;
  }
}

const AuraTableTopToolBar = (props) => {
  return getComponent(props);
};

AuraTableTopToolBar.propTypes = {
  toggleAllRowsSelected: PropTypes.func,
  selectedRowIds: PropTypes.object,
  selectedFlatRows: PropTypes.array,
  toolbarParam: PropTypes.object,
};

export default AuraTableTopToolBar;
