import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import IconButton from '@mui/material/IconButton';
import Autorenew from '@mui/icons-material/Autorenew';
import { Checkbox } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 300,
    },
  },
};

const useStyles = makeStyles({
  select: {
    '&:after': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      padding: '4px 0 5px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderRadius: 0,
      borderBottom: '2px solid #06b6d4',
    },
  },
  selectRoot: {
    '& .MuiFormControl-root': {
      margin: '0',
    },
  },
  input: {
    '&:before': {
      borderBottom: '2px solid #06b6d4',
    },
    '&:after': {
      borderBottom: '2px solid #06b6d4',
    },
  },
});

const TextFilter = ({ column, params, updateParams }) => {
  const classes = useStyles();
  const { filterValue, setFilter, paramKey } = column;
  let value = filterValue;
  if (paramKey) value = params[paramKey]

  return (
    <div>
      <Input
        className={clsx(classes.input, 'm-w-100')}
        placeholder="Search"
        value={value || ''}
        onClick={(ev) => ev.stopPropagation()}
        onChange={(ev) => {
          ev.stopPropagation();
          if (paramKey)
            updateParams(paramKey, ev.target.value)
          else
            setFilter(ev.target.value);
        }}
      />
    </div>
  );
};

export const NumberFilter = ({ column, params, updateParams }) => {
  const classes = useStyles();
  const { filterValue, setFilter, paramKey } = column;
  let value = filterValue;
  if (paramKey) value = params[paramKey]

  return (
    <div>
      <Input
        type="number"
        className={clsx(classes.input, 'm-w-100 number-no-scroll')}
        placeholder="Search"
        value={value || ''}
        onClick={(ev) => ev.stopPropagation()}
        onChange={(ev) => {
          ev.stopPropagation();
          if (paramKey)
            updateParams(paramKey, ev.target.value)
          else
            setFilter(ev.target.value);
        }}
      />
    </div>
  );
};

export const DateFilter = ({ column, params, updateParams }) => {
  const { filterValue, setFilter, paramKey } = column;
  let value = filterValue;
  if (paramKey) value = params[paramKey]

  return (
    <div>
      <Input
        type="date"
        className='w-[110px]'
        placeholder=""
        value={value || ''}
        onClick={(ev) => ev.stopPropagation()}
        onChange={(ev) => {
          ev.stopPropagation();
          if (paramKey)
            updateParams(paramKey, ev.target.value)
          else
            setFilter(ev.target.value);
        }}
      />
    </div>
  );
};

export function SelectFilter(props) {
  const { column, menuData, setSelectedValue, params, updateParams, multiple } = props
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  const { filterValue, setFilter, id, filter, paramKey, headerSxStyle = {} } = column;
  let value = filterValue;
  if (paramKey) value = params[paramKey]
  if (multiple && value && !Array.isArray(value)) value = value.split(',')

  const onChange = (ev, newValue) => {
    let targetValue = ev.target.value
    ev.stopPropagation();

    if (multiple && newValue.props.value === '') {
      targetValue = ['']
    }

    if (multiple && newValue.props.value && value) {
      value = value.filter(v => v)
      targetValue = targetValue.filter(v => v)
    }

    if (paramKey)
      updateParams(paramKey, targetValue)
    else if (filter === 'equals' && targetValue === '') {
      setFilter(null);
      if (setSelectedValue) {
        setSelectedValue(null);
      }
    } else {
      setFilter(targetValue);
      if (setSelectedValue) {
        setSelectedValue(ev);
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isEmpty = (selected) => {
    if (multiple)
      return (selected.length < 2 && selected[0] === '') || !selected.length
    return selected === ''
  }

  return (
    <div className={classes.selectRoot}>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <Select
          id={`filter-${id}}`}
          size="small"
          displayEmpty
          value={multiple ? value || [] : value || ''}
          onClick={(ev) => ev.stopPropagation()}
          onClose={handleClose}
          onOpen={handleOpen}
          multiple={multiple}
          open={multiple ? open : undefined}
          onChange={onChange}
          renderValue={(selected) => {
            if (isEmpty(selected)) {
              return <Box sx={{ color: '#8e8e8e' }}>All</Box>;
            }

            return (
              <Box
                style={{
                  textTransform: 'capitalize',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  "flex-wrap": "nowrap",
                  width: (multiple && headerSxStyle?.width) ? headerSxStyle?.width : 'initial'
                }}
              >
                {multiple ? selected.join(', ') : selected}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          className={classes.select}
        >
          <MenuItem value="" onClick={(ev) => {
            ev.stopPropagation();
            setOpen(false)
          }}>
            <em>All</em>
          </MenuItem>
          {menuData &&
            menuData.map((name) => (
              <MenuItem key={name} value={name}>
                {multiple && <Checkbox size='small' className='p-0 pr-4' checked={value?.indexOf(name) > -1} />}
                <div style={{ textTransform: 'capitalize' }}>{name}</div>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div >
  );
}

export const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((valueP) => {
    setFilter(valueP || undefined);
  }, 1000);

  return (
    <span>
      Search:{' '}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
};

export const ResetFilter = (props) => {
  return (
    <IconButton
      color="secondary"
      aria-haspopup="true"
      onClick={props.handleResetFiltersClick}
      size="large"
    >
      <Autorenew />
    </IconButton>
  );
};

export default TextFilter;
