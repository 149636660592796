import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCustomers = createAsyncThunk(
  'customersApp/customers/getCustomers',
  async (routeParams, { getState }) => {
    routeParams = routeParams || getState().customersApp.customers.routeParams;
    const response = await axios.get('/api/customers', {
      params: routeParams,
    });
    if (routeParams && (routeParams.lt === 's' || routeParams.lt === 'n')) {
      const data = await response.data;
      return { data, routeParams };
    }
    const data = await response.data.data;
    return { data, routeParams };
  }
);

export const createMultipleLogin = createAsyncThunk(
  'customersApp/customers/createMultipleLogin',
  async (payload) => {
    const response = await axios.post(`/api/createLogins`, payload);
    const data = await response.data
    return data;
  }
);

export const getShipTo = createAsyncThunk(
  'customersApp/customers/getShipTo',
  async (customerId, { getState }) => {
    const response = await axios.get(`/api/customerProfile/${customerId}`);
    const data = await response.data;
    return data
  }
);

export const getCustomersShipment = createAsyncThunk('customersApp/customers/getCustomersShipment',
  async (routeParams, { getState, dispatch }) => {
    const response = await axios.get('/api/customers', {
      params: routeParams,
    });
    return response;
  }
)
export const getCustomerInfo = createAsyncThunk(
  '/customersApp/customers/getCustomerInfo',
  async () => {
    const response = await axios.get('/api/getCustomerInfo');
    return response;
  }
);

export const removeCustomers = createAsyncThunk(
  'customersApp/customers/removeCustomers',
  async (customerIds, { dispatch, getState }) => {
    await axios.post('/api/client-customers-app/remove-client-customers', { customerIds });
    return customerIds;
  }
);

const customersAdapter = createEntityAdapter({});

export const { selectAll: selectCustomers, selectById: selectCustomersById } =
  customersAdapter.getSelectors((state) => state.customersApp.customers);

const customersSlice = createSlice({
  name: 'customersApp/customers',
  initialState: customersAdapter.getInitialState({
    searchText: '',
    customer: null,
    routeParams: {},
    selectedCustomers: []
  }),
  reducers: {
    setCustomersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setCustomer: (state, action) => {
      state.customer = action.payload || null;
    },
    setSelectedCustomers: (state, action) => {
      state.selectedCustomers = action.payload.data
    }
  },
  extraReducers: {
    [removeCustomers.fulfilled]: (state, action) =>
      customersAdapter.removeMany(state, action.payload),
    [getCustomers.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      if (
        action.payload.params &&
        (action.payload.params.lt === 's' || action.payload.params.lt === 'n')
      ) {
        // return
      } else {
        customersAdapter.setAll(state, data);
        state.routeParams = routeParams;
        state.searchText = '';
      }
    },
  },
});

export const { setCustomersSearchText, setCustomer, setSelectedCustomers } = customersSlice.actions;

export default customersSlice.reducer;
