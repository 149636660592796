import { lazy } from 'react';

const MainDashboardApp = lazy(() => import('./MainDashboardApp'));
const Notifications = lazy(() => import('../../apps/backoffice/notifications/Notifications'))
const Profile = lazy(() => import('../../apps/backoffice/profile/Profile'))
const ChangePassword = lazy(() => import('../../apps/backoffice/profile/ChangePassword'))

const MainDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboard',
      element: <MainDashboardApp />,
    },
    {
      path: 'notifications',
      element: <Notifications />,
    },
    {
      path: 'profile/:userId',
      element: <Profile />
    },
    {
      path: 'change-password/:userId/:id',
      element: <ChangePassword />
    }
  ],
};

export default MainDashboardAppConfig;
