import { Controller, useWatch } from 'react-hook-form';
import { createFilterOptions } from '@mui/base';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';

const filter = createFilterOptions();
const isArrayofStrings = (arr) => Array.isArray(arr) && arr.every(item => typeof item === 'string');
const isFieldEmpty = (fieldValue) => {
  if (
    fieldValue === undefined ||       // Check if it's undefined
    fieldValue === null ||            // Check if it's null
    fieldValue === '' ||              // Check if it's an empty string
    (typeof fieldValue === 'object' && fieldValue.name === '') // Check if it's an object with { name: '' }
  ) {
    return true;
  }
  return false;
};

export default function AuraFreeSoloControlledAutocomplete({
  onChange: ignored,
  control,
  controlName,
  inputValues,
  labelName,
  readOnly,
  hideClearable = false,
  onUpdate = null,
  textClassName = null,
  required = false,
  stopAdd = true
}) {
  const [open, setOpen] = useState(false);
  const [isCustomOption, setIsCustomOption] = useState(false);
  const classNames = textClassName || 'mt-8 mb-16';

  // Watch the specific field value
  const fieldValue = useWatch({ control, name: controlName });

  // This function checks if a given value is a custom option
  const checkIsCustomOption = (value) => {
    if (inputValues?.length === 0 || isFieldEmpty(value)) return false
    if (isArrayofStrings(inputValues)) {
      return value && !inputValues.some((option) => option === value);
    }
    return value && !inputValues.some((option) => option?.name === value || option === value || option?.name === value?.name);
  };

  useEffect(() => {
    // Check if the current value (populated by API or other means) is a custom option
    if (fieldValue) {
      const isCustom = checkIsCustomOption(fieldValue);
      setIsCustomOption(isCustom);
    }
  }, [fieldValue, inputValues]); // Run this effect whenever value or inputValues change

  return (
    <Controller
      render={({ field: { onChange, value, ...props } }) => (
        <Autocomplete
          readOnly={readOnly}
          fullWidth
          onChange={(event, newValue) => {
            onChange(newValue);
            if (onUpdate) {
              onUpdate(event, newValue);
            }
            // Check if the selected value is a custom option
            const isCustom = newValue && !inputValues.some((option) => option.name === newValue || option === newValue || option.name === newValue?.name);
            setIsCustomOption(isCustom);
          }}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting && stopAdd) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }else if(!filtered?.length) {
              filtered = []
            }
            return filtered;
          }}
          open={open}
          onOpen={() => !readOnly && setOpen(true)}
          onClose={() => setOpen(false)}
          disableClearable={readOnly || hideClearable}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          value={value}
          options={inputValues}
          getOptionLabel={(option) => {
            if (option) {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              if (option.name) {
                return option.name;
              }
              return '';
            }
            return '';
          }}
          renderOption={(props1, option) => <li {...props1}>{typeof option === 'string' ? option : option.name}</li>}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={labelName}
              margin="normal"
              variant="outlined"
              className={classNames}
              fullWidth
              size="small"
              required={required}
              InputProps={{
                ...params.InputProps,
                readOnly,
                style: {
                  color: isCustomOption ? '#4f46e5' : 'inherit', // Highlight text in #4f46e5 if custom option
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderLeftWidth: isCustomOption ? "4px" : "0px",
                  borderRadius: "0px",
                  borderColor: isCustomOption ? '#4f46e5' : '#c4c4c4', // Default color
                  '& fieldset': {
                    borderColor: isCustomOption ? '#4f46e5' : '#c4c4c4', // Default color
                  },
                },
                '& .MuiInputLabel-root': {
                  color: isCustomOption ? '#4f46e5' : '#6b7280', // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#4f46e5', // Focused label color
                },
              }}
            />
          )}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      control={control}
      name={controlName}
      forcePopupIcon
    />
  );
}
