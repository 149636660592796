import { Card, CardContent, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isSupplier } from 'app/main/apps/common/AuraFunctions';
import AuraBaseDialog from 'app/main/apps/common/AuraBaseDialog';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import ShipmentHeader from 'app/main/apps/backoffice/shipments/dialogs/ShipmentHeader';
import { closeSupplierShippingDialog, performAction } from '../../store/orderSlice';
import { getContainerNumber, getOrders, viewSupplierShipment } from '../../store/ordersSlice';

function SupplierShipping({ submitSupplierShipping }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const { props, param, completeOrderData, type: viewType, data, filteredData } = useSelector(
        ({ eCommerceApp }) => eCommerceApp?.order?.createSupplierOrderDialog
    );
    const isReadOnly = viewType === 'view';
    const supplierShipmentValues = useSelector(
        ({ eCommerceApp }) => eCommerceApp?.orders?.supplierShipmentValues
    );
    const schema = yup.object().shape({
        container_number: yup.string().required('You must provide container number'),
    });
    const currentUser = useSelector(({ user }) => user);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            packaging_type: "",
            length: "",
            width: "",
            height: "",
            weight: "",
            container_number: "",
            type: "",
            size: "",
            eta_date: "",
        },
        resolver: yupResolver(schema),
    });
    const containerFields = [
        { label: "Container No", name: "container_number", type: 'text' },
        // { label: "Type", name: "type", type: 'text' },
        // { label: "Size", name: "size", type: 'number' },
    ];
    const cargoFields = [
        { label: 'Weight', name: 'weight' },
        { label: 'Packaging Type', name: 'packaging_type' }
    ]

    const { control, reset, formState: { errors }, setValue } = methods;

    useEffect(() => {
        if (!props?.open) {
            reset();
            setLoading(false)
        }
    }, [reset, props?.open]);

    useEffect(() => {
        if (Object.keys(supplierShipmentValues).length)
            reset(supplierShipmentValues)
    }, [supplierShipmentValues]);

    useEffect(() => {
        if (props?.open && isSupplier(currentUser.role))
            dispatch(getContainerNumber({ order_ids: param }))
                .unwrap()
                .then((res) => { setValue('container_number', res?.container_number) })
    }, [props?.open]);

    useEffect(() => {
        if (props?.open && viewType === 'view') {
            setLoading(true)
            dispatch(viewSupplierShipment(param))
                .finally(() => setLoading(false))
        }
    }, [props?.open, viewType]);

    const handleSubmit = (values) => {
        setLoading(true)
        submitSupplierShipping(values)?.finally(() => setLoading(false))
    }

    const onClose = () => dispatch(closeSupplierShippingDialog());

    const totalData = viewType === 'create' ? { ordersCount: 1 } : {
        weight: supplierShipmentValues.total_weight,
        quantity: supplierShipmentValues.total_quantity,
        ordersCount: completeOrderData?.ordersCount || supplierShipmentValues?.count
    }

    const headerData = {
        enable: true,
        component: ShipmentHeader,
        data: { ...completeOrderData, showShipTo: false, ...totalData },
    };

    const enableButton = () => {
        return loading
    }

    return (
        <FormProvider {...methods}>
            <AuraBaseDialog
                dialogProps={{ open: props?.open }}
                closeDialog={onClose}
                dialogParam={{
                    title: viewType === 'view' ? 'View Shipment' : 'Create Shipping',
                    submitButtonName: 'Create Shipping',
                    hideDialogActions: isReadOnly,
                }}
                onSubmit={handleSubmit}
                disableSubmitButton={enableButton}
                header={headerData}
            >
                {loading ? <Loader /> :
                    <>
                        <div className='flex mt-10 gap-10'>
                            {containerFields.map(({ label, name, type }) => (
                                <Controller
                                    control={control}
                                    name={name}
                                    rules={{ required: true }}
                                    key={label}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-24"
                                            label={label}
                                            id="unit_price"
                                            variant="outlined"
                                            size="small"
                                            type={type}
                                            required={name === 'container_number'}
                                            error={!!errors[name]}
                                            helperText={errors[name]?.message}
                                            // InputProps={{ inputProps: { min: 0 }, readOnly: ['create', 'view'].includes(viewType) }}
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                    )}
                                />
                            ))}
                        </div>
                    </>}
            </AuraBaseDialog>
        </FormProvider>
    );
};

export default SupplierShipping;