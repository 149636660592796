import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUsers } from './employeesSlice';
import { getEmployeeInitValues } from '../employee/EmployeeFormInit';

export const getEmployee = createAsyncThunk(
  'employeesApp/employee/getEmployee',
  async (id, { getState }) => {
    const response = await axios.get(`/api/employees/${id}`);
    const data = await response.data.employee;
    return { data };
  }
);

export const getEmployeeDetails = createAsyncThunk(
  'employeesApp/employee/getEmployeeDetails',
  async ({ id, params }, { dispatch, getState }) => {
    const response = await axios.get(`/api/employeeDetails/${id}`, { params });
    const data = await response.data.employee;
    return data ? getEmployeeInitValues(data) : data;
  }
);

export const addEmployee = createAsyncThunk(
  'employeesApp/employee/addEmployee',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post('/api/employees', inputData);
    const data = await response.data;
    dispatch(getUsers());
    return data;
  }
);

export const updateEmployee = createAsyncThunk(
  'employeesApp/employee/updateEmployee',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.put(`/api/employees/${inputData.id}`, inputData);
    const data = await response.data.data;
    dispatch(getUsers());
    return data;
  }
);

export const createEmployeeLogin = createAsyncThunk(
  'employeesApp/employee/createEmployeeLogin',
  async (id, { dispatch, getState }) => {
    const response = await axios.post(`/api/createUserLogin/${id}`);
    const data = await response.data;
    dispatch(getUsers());
    return data;
  }
);

export const removeEmployee = createAsyncThunk(
  'employeesApp/employee/removeEmployee',
  async (customerId, { dispatch, getState }) => {
    try {
      const data = await axios.delete(`/api/employees/${customerId}`);
      return customerId;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const checkUnique = createAsyncThunk(
  'employeesApp/employee/checkUnique',
  async (inputData, { dispatch, getState }) => {
    const param = { type: 'email' };
    param.email = inputData.email;
    if (inputData.id) {
      param.id = inputData.id;
    }
    const response = await axios.post(`/api/checkUnique/email`, param);
    const data = await response.data;
    return data;
  }
);

export const checkUniqueOrderNo = createAsyncThunk(
  'employeesApp/employee/checkUniqueOrderNo',
  async ({ orderNo, id }, { dispatch, getState }) => {
    const response = await axios.post(`/api/checkUniqueOrderNumber/${id}?order_no=${orderNo}`);
    const data = await response.data;
    return data;
  }
);

export const checkPassword = createAsyncThunk(
  'employeesApp/employee/checkPassword',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post(`/api/user/password`, inputData);
    const data = await response.data;
    return data;
  }
);

export const updateDevice = createAsyncThunk(
  'employeesApp/employee/updateDevice',
  async (deviceId, { dispatch, getState }) => {
    try {
      const data = await axios.post(`/api/devices/${deviceId}`, {});
      return deviceId;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const initState = {
  loading: false,
  data: null,
  employeeDialog: {
    props: {
      open: false,
    },
    data: null,
    param: null,
  },
};

const employeeSlice = createSlice({
  name: 'employeesApp/employee',
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    newEmployee: {
      reducer: (state, action) => {
        state.data = action.payload;
      },
      prepare: (event) => ({
        payload: getEmployeeInitValues(),
      }),
    },
    resetEmployee: (state, action) => {
      state.data = null;
    },
    openEmployeeDialog: (state, action) => {
      state.employeeDialog = {
        props: {
          open: true,
        },
        data: action.payload.data,
        param: action.payload.param,
      };
    },
    closeEmployeeDialog: (state, action) => {
      state.employeeDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
  },
  extraReducers: {
    [getEmployee.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getEmployeeDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [addEmployee.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addEmployee.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateEmployee.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateEmployee.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setLoading,
  resetLoading,
  newEmployee,
  resetEmployee,
  openEmployeeDialog,
  closeEmployeeDialog,
} = employeeSlice.actions;

export default employeeSlice.reducer;
