import * as yup from 'yup';
import { emptySchema } from 'app/main/apps/common/AuraFunctions';

export function getEmployeeInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    user_id: data && data.user_id ? data.user_id : null,
    first_name: data && data.first_name ? data.first_name : '',
    last_name: data && data.last_name ? data.last_name : '',
    email: data && data.email ? data.email : '',
    phone: data && data.phone ? data.phone : '',
    mobile: data && data.mobile ? data.mobile : '',
    title: data && data.title ? data.title : '',
    about: data && data.about ? data.about : '',
    password: data && data.password ? data.password : '',
    passwordConfirm: data && data.passwordConfirm ? data.passwordConfirm : '',
    roles: data  && data.roles ? data.roles : [],
    address_id: data && data.address_id ? data.address_id : null,
    address: {
      address_line1:
        data && data.address && data.address.address_line1 ? data.address.address_line1 : '',
      address_line2:
        data && data.address && data.address.address_line2 ? data.address.address_line2 : '',
      city: data && data.address && data.address.city ? data.address.city : '',
      postcode: data && data.address && data.address.postcode ? data.address.postcode : '',
      region: data && data.address && data.address.region ? data.address.region : '',
    },
    logins: data && data.logins ? data.logins : [],
    devices: data && data.devices ? data.devices : [],
  };
}

/**
 * Form Init Values
 */
export default function getInitValues(type, data) {
  if (type && type === 'employee') {
    return getEmployeeInitValues(data);
  }
  return { id: data && data.id ? data.id : null };
}

export const employeeSchema = yup.object().shape({
  first_name: yup.string().required('You must enter a first name'),
  last_name: yup.string().required('You must enter a last name'),
  email: yup.string().required('You must enter a email').email('You must enter a valid e-mail.'),
  roles: yup.array().required('You must enter a roles').min(1, 'You must select a role'),
});

/**
 * Form Validation Schema
 */
const employeeAddSchema = yup.object().shape({
  first_name: yup.string().required('You must enter a first name'),
  last_name: yup.string().required('You must enter a last name'),
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  roles: yup.array().required('You must enter a roles').min(1, 'You must select a role'),
});

const employeeEditSchema = yup.object().shape({
  first_name: yup.string().required('You must enter a first name'),
  last_name: yup.string().required('You must enter a last name'),
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  roles: yup.array().required('You must enter a roles').min(1, 'You must select a role'),
});

/**
 * Form Validation Schema
 */
export function getSchema(name, type) {
  if (name && name === 'employee') {
    return !type || type === 'Add' ? employeeAddSchema : employeeEditSchema;
  }
  return emptySchema;
}
