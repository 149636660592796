import TextField from '@mui/material/TextField';
import { matchSorter } from 'match-sorter';
import { getShipTo } from 'app/main/apps/client/store/customersSlice';
import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { getSalesPersons } from 'app/main/apps/client/store/customerSlice';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

function QuickOrderEdit(props) {
    const { orderTypes } = useSelector(({ eCommerceApp }) => eCommerceApp?.orders)
    const dispatch = useDispatch()

    const methods = useFormContext();
    const { control, formState } = methods;
    const { errors } = formState;
    const { customer_id: customerId } = useSelector(({ eCommerceApp }) => eCommerceApp?.order?.data)
    const [shipTo, setShipTo] = useState([])
    const [salesPersons, setSalesPerson] = useState([])

    useEffect(() => {
        dispatch(getShipTo(customerId)).then(({ payload }) => setShipTo(payload.profile))
        dispatch(getSalesPersons()).then((response) => {
            const salesPersonTemp =
                response && response.payload && response.payload ? response.payload : [];
            setSalesPerson(salesPersonTemp)
        })
    }, [])

    return (
        <>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className="flex w-full mt-7 block">
                    <Controller
                        name="order_no"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onChange={(e) => {
                                    field.onChange(e.target.value);
                                    if (!errors.order_no && e.target.value?.length > 1) {
                                        props.validateOrderNo(e, e.target.value);
                                    }
                                }}
                                id="order_no"
                                label="Order No*"
                                type="text"
                                variant="outlined"
                                fullWidth
                                size="small"
                                error={!!errors?.order_no?.message}
                                helperText={errors?.order_no?.message}
                            />
                        )}
                    />
                    {props.isUniqueOrderNo && (
                        <FormHelperText className="text-red mb-24">Order No Already Exist</FormHelperText>
                    )}
                </div>

                <div className="flex w-full">
                    <Controller
                        control={control}
                        name="eta_date"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mt-8 mb-16"
                                id="eta_date"
                                label="Est. Delivery"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        )}
                    />

                </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className="flex w-full">
                    <AuraControlledAutocomplete
                        labelName='Order Type'
                        control={control}
                        controlName='order_type'
                        inputValues={orderTypes}
                        required
                        error={!!errors?.order_type?.message}
                        helperText={errors?.order_type?.message}
                        className={errors && errors?.order_type?.message ? 'mt-8 mb-0' : 'mt-8 mb-16'}
                    />
                </div>
                <div className="flex w-full mt-7">
                    <Controller
                        name="location"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="location"
                                label="Location*"
                                type="text"
                                variant="outlined"
                                fullWidth
                                size="small"
                                error={!!errors?.location?.message}
                                helperText={errors?.location?.message}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className='flex w-full md:w-1/2'>
                    <AuraControlledAutocomplete
                        labelName='Ship To'
                        control={control}
                        controlName='ship_to'
                        inputValues={shipTo}
                        className={errors && errors?.ship_to?.message ? 'mt-8 mb-0' : 'mt-8 mb-16'}
                    />
                </div>
                <div className="flex w-full md:w-1/2">
                    <AuraControlledAutocomplete
                        control={control}
                        controlName="sales_person"
                        inputValues={salesPersons || []}
                        filterOptions={(options, { inputValue }) =>
                            matchSorter(options, inputValue, {
                                keys: ['name'],
                                threshold: matchSorter.rankings.STARTS_WITH,
                            })
                        }
                        labelName="Sales Person"
                        className={errors && errors?.salesperson_id?.message ? 'mt-8 mb-0' : 'mt-8 mb-16'}
                    />
                </div>
            </div>
        </>
    );
}

export default QuickOrderEdit;
